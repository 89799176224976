import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography, Grid, Button, AddIcon, CircularProgress, Box, Menu, MenuItem } from '../mui';
import CustomToolDialogue from './CustomToolDialog';
import CustomCategoryDialog from './CustomCategoryDialog';
import { deleteCustomToolById, getAllTools, handleSystemToolsAction, handleSystemToolsUpdate } from './apiCalls';
import { setActiveRerender, setCustomTools, setSelectedPrompt, setSystemTools } from '../redux/slices/MasterSlice';
import SystemToolsComp from '../components/tool/SystemTools';
import CustomToolsComp from '../components/tool/CustomTools';

const ToolPage = () => {
  const [hoverFieldId, setHoverFieldId] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [systemField, setSystemField] = useState(null);
  const [editFieldId, setEditFieldId] = useState(null);
  const [editDescId, setEditDescId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDesc, setEditedDesc] = useState('');
  const [open, setOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [dltBtnLoader, setDltBtnLoader] = useState(false);
  const [customToolId, setCustomToolId] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // Destructure useSelector values
  const activeRerender = useSelector((state) => state.Master.activeRerender);
  const systemTools = useSelector((state) => state.Master.systemTools);
  const customTools = useSelector((state) => state.Master.customTools);
  const search = JSON.parse(localStorage.getItem('search'));

  const dispatch = useDispatch();

  const handleToggle = async (id, action) => {
    // optimistic rendering
    dispatch(
      setSystemTools(
        systemTools.map((item) => {
          if (item.id === id) {
            return { ...item, is_disabled: !item.is_disabled };
          }
          return item;
        })
      )
    );
    const response = await dispatch(handleSystemToolsAction(id, !action));
    if (response) {
      // For Search Title
      if (search.id === id) {
        dispatch(setSelectedPrompt(null));
        localStorage.removeItem('search');
      }
    }
  };

  const handleNameChange = async (id) => {
    setBtnLoader(true);

    try {
      const payload = {
        name: editedTitle,
      };
      const response = await dispatch(handleSystemToolsUpdate(id, payload));
      if (response) {
        // optimistic rendering
        dispatch(
          setSystemTools(
            systemTools.map((item) => {
              if (item.id === id) {
                return { ...item, name: editedTitle };
              }
              return item;
            })
          )
        );
        // For Search Title
        if (search.id === id) dispatch(setSelectedPrompt(null));
      }
      setEditFieldId(null);
      setBtnLoader(false);
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const handleDescChange = async (id) => {
    setBtnLoader(true);

    try {
      const payload = {
        description: editedDesc,
      };
      const response = await dispatch(handleSystemToolsUpdate(id, payload));
      if (response) {
        // optimistic rendering
        dispatch(
          setSystemTools(
            systemTools.map((item) => {
              if (item.id === id) {
                return { ...item, description: editedDesc };
              }
              return item;
            })
          )
        );
      }
      setBtnLoader(false);
      setEditDescId(null);
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const editPersonality = async (e, id) => {
    e.stopPropagation();
    setCustomToolId(id);
  };

  const deletePersonality = async (e, id) => {
    e.stopPropagation();
    setDltBtnLoader(true);
    const response = await dispatch(deleteCustomToolById(id));
    if (response) {
      dispatch(setActiveRerender());
      if (search.id === id) {
        dispatch(setSelectedPrompt(null));
        localStorage.removeItem('search');
      }
    }
    setDltBtnLoader(false);
  };

  useEffect(() => {
    if (customToolId) setOpen(true);
  }, [customToolId]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const response = await dispatch(getAllTools(controller.signal));
      if (response) {
        dispatch(setCustomTools(response.customTools));
        dispatch(setSystemTools(response.systemTools));
      }
      setLoader(false);
    })();
    return () => controller.abort();
  }, [dispatch, activeRerender]);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleAddTools = () => {
    setOpen(true);
    setOpenCategory(false);
    handleMenuClose();
  };

  const handleAddCategory = () => {
    setOpen(false);
    setOpenCategory(true);
    handleMenuClose();
  };

  return !loader ? (
    <Grid container justifyContent="center" alignItems="center" spacing={3} my={4} mb={12}>
      <Grid item xs={10}>
        <Typography variant="h6">System Tools</Typography>
      </Grid>
      <Grid item xs={10}>
        <SystemToolsComp
          Tools={systemTools}
          btnLoader={btnLoader}
          editFieldId={editFieldId}
          editedTitle={editedTitle}
          editedDesc={editedDesc}
          editDescId={editDescId}
          systemField={systemField}
          setEditedTitle={setEditedTitle}
          setEditFieldId={setEditFieldId}
          setEditDescId={setEditDescId}
          setEditedDesc={setEditedDesc}
          setSystemField={setSystemField}
          handleToggle={handleToggle}
          handleNameChange={handleNameChange}
          handleDescChange={handleDescChange}
        />
      </Grid>
      <Grid item xs={10}>
        <Divider />
      </Grid>
      <Grid item xs={10}>
        <Typography component="span" variant="h6">
          Custom Tools
        </Typography>
        <Button variant="contained" sx={{ float: 'right' }} onClick={handleMenuOpen} endIcon={<AddIcon />}>
          Add
        </Button>
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleAddTools}>Add Tools</MenuItem>
          <MenuItem onClick={handleAddCategory}>Add Category</MenuItem>
        </Menu>
      </Grid>
      <Grid item xs={10}>
        <CustomToolsComp
          Tools={customTools}
          hoverFieldId={hoverFieldId}
          dltBtnLoader={dltBtnLoader}
          editPersonality={editPersonality}
          deletePersonality={deletePersonality}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          setHoverFieldId={setHoverFieldId}
        />
      </Grid>
      <Divider sx={{ my: 2 }} />
      {open && (
        <CustomToolDialogue
          customToolId={customToolId}
          setCustomToolId={setCustomToolId}
          openDialog={open}
          setOpenDialog={setOpen}
        />
      )}
      {openCategory && (
        <CustomCategoryDialog
          customToolId={customToolId}
          setCustomToolId={setCustomToolId}
          openDialog={openCategory}
          setOpenDialog={setOpenCategory}
        />
      )}
    </Grid>
  ) : (
    <Box display="flex" justifyContent="center" my={20}>
      <CircularProgress />
    </Box>
  );
};

export default ToolPage;
