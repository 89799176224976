import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// import userFlow from 'userflow.js';
// import moment from 'moment';
import Nav from './nav';
import Header from './header';
// import axios from '../../utils/axios';

const APP_BAR_MOBILE = 64;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 40,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout({ handleThemeChange, isDarkMode, textSize, setTextSize }) {
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   const accessToken = localStorage.getItem('accessToken');
  //   if (accessToken) {
  //     (async () => {
  //       try {
  //         await userFlow.init('ct_vpcbxbyz4rchxdvmx2wimpecaq');
  //         const payload = { access_token: accessToken };
  //         const { data: response } = await axios.post(`/get_user_info`, JSON.stringify(payload));
  //         if (response.status) {
  //           const responseData = response.data;
  //           userFlow.identify(responseData.sub, {
  //             name: responseData.username,
  //             email: responseData.email,
  //             signed_up_at: moment(new Date()).toISOString(),
  //           });
  //         }
  //       } catch (err) {
  //         console.log('err', err);
  //       }
  //     })();
  //   }
  // }, []);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav
        openNav={open}
        onCloseNav={() => setOpen(false)}
        handleThemeChange={handleThemeChange}
        isDarkMode={isDarkMode}
        textSize={textSize}
        setTextSize={setTextSize}
      />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
